export type ClientSecretResponse = {
  client_secret: string;
}

export enum PaymentType {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

export type PaymentStatusResponse = {
  status: PaymentStatus;
  order_id: string;
}
