enum SPACING {
  NONE = 0,
  XS = 1,
  SM = 2,
  MD = 2.5,
  LG = 3,
  XL = 4,
  XXL = 5,
}

export default SPACING;
