import { styled } from '@mui/material/styles';
import { headerHeight, headerMobilePadding, headerPadding } from './constants';

export const StyledContainer = styled('div')(({ theme }) => ({
  height: headerHeight,
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  background: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 100,
  [theme.breakpoints.up('sm')]: {
    padding: `0 ${headerPadding}`,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${headerMobilePadding}`,
  },
}));
