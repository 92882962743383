import stepper from './stepper/en';

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  header: {
    modalText:
      'Are you sure you want to interrupt the booking process? The data entered will not be saved',
    modalOk: 'Ok',
    modalCancel: 'Cancel',
    logIn: `Log In`,
  },
  stepper,
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  booking_page_title: `Booking | Step `,
  booking_code: {
    title: 'Booking Code',
    subtitle:
      'Please, enter your last name and airline booking code so that we can synchronize your order details.',
    name: 'Last Name',
    code: 'Airline booking code',
    tooltip_text: 'You can find booking code on your ticket. For more information',
    tooltip_link: 'click here',
    validation: {
      name_max: 'Max length is 60 characters',
      name_req: 'Last name is required',
      code_min: 'Min length is 6 characters',
      code_max: 'Max length is 12 characters',
      code_req: 'Booking code is required',
      code_format: 'Booking code must contain only letters and numbers',
    },
    notification1:
      'Great news! While we’re currently serving a few cities for Dusseldorf Airport, if your city isn’t on the list yet, don’t worry! We’re working hard to expand and hope to serve your city soon. Stay tuned for updates!',
  },
  personal_information: {
    title: 'Personal Information',
    subtitle:
      'Kindly provide the contact information of a responsible individual to ensure effective communication and notifications regarding your baggage check-in.',
    email: 'Email',
    phone: 'Phone number',
    first_name: 'First Name',
    last_name: 'Last Name',
    passenger: 'Passenger',
    validation: {
      first_name_req: 'First name is required',
      last_name_req: 'Last name is required',
      email_req: 'Email is required',
      email_invalid: 'Incorrect formatting, email should look like name@domain',
      phone_req: 'Phone number is required',
      phone_invalid: 'Incorrect formating, please enter a valid phone number',
    },
    buttons: {
      add_passenger: 'Add passenger',
      add_new_passenger: 'Add passenger to group',
      update_passenger: 'Save',
    },
  },
  flight_info: {
    title: 'Flight Information',
    subtitle:
      'Kindly furnish your flight details to facilitate the precise scheduling and delivery of your baggage.',
    airportFrom: 'Airport of departure',
    airportTo: 'Airport of destination',
    flightNumber: 'Flight number',
    flightDate: 'Date and time of departure',
    validation: {
      from_req: 'Airport of departure is required',
      to_req: 'Airport of destination is required',
      to_max: 'Max length is 50 characters',
      num_req: 'Flight number is required',
      num_max: 'Max length is 6 characters',
      date_req: 'Date of departure is required',
      date_min: 'Select date in future',
      time_req: 'Time of departure is required',
      code_format: 'Flight number must contain only letters and numbers',
    },
  },
  baggage_info: {
    title: 'Baggage Information',
    notification:
      'Please ensure that your booked baggage details with the airline align with our service for home door check-in. Any discrepancies, such as overweight or unregistered baggage, may lead to service issues and airline rejection. We advise you to confirm and clear any changes directly with the airline and update our booking for collection and check-in at least a week before our scheduled delivery pickup time. Thank you for your cooperation.',
    subtitle:
      'Kindly specify the number, type, and weight of bags you would like to be delivered, allowing us to determine the required quantity of Smartporters’ safety measurements.',
    weight: 'Weight',
    allowance_title: 'Your baggage allowance',
    exceed_allowance_error:
      'You have exceeded your baggage allowance, remove some baggage to avoid it.',
    total: 'Total',
    bags: {
      small: {
        title: 'Small Bag',
        subtitle: 'Max 55 x 40 x 20 cm',
      },
      medium: {
        title: 'Medium Bag',
        subtitle: 'Max 70 x 50 x 30 cm',
      },
      large: {
        title: 'Large Bag',
        subtitle: 'Max 80 x 65 x 45 cm',
      },
    },
  },
  no_data: {
    notification:
      'Presently, Smartporters can’t connect to your airline for booking details, but this feature will be available soon. Please enter your details manually, ensuring accuracy. Smartporters is not responsible for inaccuracies.',
    link: 'View Policy',
  },
  step_nav: {
    previous: 'Previous step',
    next: 'Next step',
    last: 'Pay now',
  },
  collection: {
    title: 'Pickup details',
    subtitle:
      'Kindly furnish details regarding the designated location and time for baggage collection to facilitate a seamless and punctual retrieval of your belongings.',
    subtitle2:
      'Prior to the arrival of our agent for baggage check-in, please ensure you have completed the check-in process and obtained your valid boarding pass.',
    address: 'Postal code and address *',
    appartment: 'Apartment number',
    date: 'Date and time slot of collection * (date - within 12-72 hours before departure)',
    btn_manually: 'Enter address manually',
    btn_choose: 'Choose address from the list',
    post_code: 'Postal code *',
    city: 'City',
    street: 'Street *',
    building: 'Building number *',
    validation: {
      date_req: 'Date of collection is required',
      time_req: 'Time of collection is required',
      date_min: 'Select date in future',
      date_range: 'Selected date must be within 12-72 hours before departure',
      post_req: 'Postal code is required',
      app_max: 'Max length is 15 characters',
      build_max: 'Max length is 15 characters',
      post_max: 'Max length is 5 characters',
    },
    error: {
      no_distance:
        'The address you specified could not be found, please check the provided information.',
      too_far:
        'Calculated distance exceeds the maximum allowed distance, please check the provided information.',
    },
  },
  check: {
    title: 'Confirmation',
    notification:
      'Before the scheduled baggage collection at your specified location (home, hotel, etc.), please ensure that you have completed check-in with the airline and possess a valid boarding pass (either digital or printed). Our delivery agent will require this documentation at the time of collection. Kindly note that, as per airline regulations, we are unable to collect baggage from passengers who have not completed the check-in process with the airline.',
    subtitle1:
      'Please ensure the accuracy of your order details and verify their alignment with your flight booking to prevent any inconveniences or delays at the airport.',
    subtitle2: 'Order Summary',
    personal: 'Personal information:',
    address: 'Address:',
    euro: 'EUR',
    total: 'Total:',
    pay_card: 'Pay with card',
    collection: {
      datetime: 'Collection date and time:',
      date: 'Date:',
      time: 'Time slot:',
      distance: 'Distance:',
      short: 'up to 40 km',
      long: 'over 40 km',
    },
    flight: {
      title: 'Flight information:',
      airport: 'Airport:',
      number: 'Flight:',
    },
    bags: {
      title: 'Baggage information:',
      weight: 'Weight: ',
      kg: 'kg',
      no_info: 'No information, check Baggage page',
    },
    agreements: {
      text: 'I agree with Smartporters',
      terms: 'Terms and Conditions',
      policy: 'Privacy Policy',
    },
  },
  payment_success: {
    title: 'Your payment was successful!',
    body1:
      "We have sent mail with order details to your email. If you didn't receive an email-download the order details below",
    body2: 'Do you want to book another off-airport baggage check-in with SmartPorters?',
    download: 'Download',
    new: 'Make new booking',
  },
  payment_unsuccessful: {
    title: 'Unfortunately, your payment was unsuccessful',
    body: 'Please, try again later or contact your bank',
    return: 'To my booking',
  },
  welcome_page_title: `Smartporters | Home `,
  welcome_page: {
    title: 'Travel Smart Without Carrying Your Belongings!',
    subtitlerow1: 'We collect your baggage from your home door, hotel, office etc.,',
    subtitlerow2: 'by taking care of safety, trackable delivery and check-in at airport!',
    subtitle2: 'You Pickup Your Belongings At Your Destination',
    subtitle3: 'Getting Started',
    message1:
      'Book Smartporters baggage collection and check-in service in cooperation with TUI starting from XY euros',
    message2: 'The service is available for checked-in passengers only',
    tui_text: 'You can complete an online check in on the ',
    tui_link: 'TUI website',
    checkbox: 'I understand that I must be checked in at the time of baggage collection',
    book_now: 'Book now',
    terms: 'Terms and Conditions',
    policy: 'Privacy Policy',
    tooltip1: 'We have implemented such pricing structure based on distance for your convenience:',
    tooltip2:
      'Prices are divided into two parts: one for locations within a 40 km range and another dependent on distance.',
    tooltip3:
      'Our system will automatically calculate the distance from the airport based on your input.',
    tooltip4:
      'An additional cost of, for example, €1 per kilometer will be applied for distances beyond 40 km.',
  },
};

export default en;
