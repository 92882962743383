// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
export const ROOTS_BOOKING = '/booking';

// ----------------------------------------------------------------------
export const PATH_NOT_FOUND = '404';

export const PATH_AUTH = {
  login: '/login',
};

export const SUBPATH_BOOKING = {
  success: 'success',
  error: 'error',
  stripe: 'stripe',
};

export const PATH_WELCOME = {
  root: '/home',
};

export const PATH_BOOKING = {
  root: ROOTS_BOOKING,
  success: path(ROOTS_BOOKING, `/${SUBPATH_BOOKING.success}`),
  error: path(ROOTS_BOOKING, `/${SUBPATH_BOOKING.error}`),
  stripe: path(ROOTS_BOOKING, `/${SUBPATH_BOOKING.stripe}`),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, '/one'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
