import { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, Button, Modal, Typography, Stack } from '@mui/material';

import { useLocales } from 'src/locales';
import ASSETS from 'src/constants/assets';
import { PATH_WELCOME } from 'src/routes/paths';
import SPACING from 'src/theme/spacing';

import { STEPS } from 'src/components/step-navigation/steps.enum';
import { defaultDisableLink, defaultSize, defaultWithTitle } from 'src/components/logo/constants';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  size?: number;
  withTitle?: boolean;
  step?: number;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      disabledLink = defaultDisableLink,
      size = defaultSize,
      withTitle = defaultWithTitle,
      step,
      sx,
      ...other
    },
    ref
  ) => {
    const { translate } = useLocales();

    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const handleConfirmNavigate = () => {
      navigate(PATH_WELCOME.root);
      setOpenModal(false);
    };

    const handleLogoCLick = () => {
      console.log(step);
      if (!step || step === STEPS.CODE) {
        navigate(PATH_WELCOME.root);
      } else {
        setOpenModal(true);
      }
    };

    const modalBody = (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          minWidth: '30%',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          p: SPACING.XXL,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="subtitle1"
          component="h2"
          sx={{ textAlign: 'center' }}
        >
          {`${translate('header.modalText')}`}
        </Typography>
        <Stack
          direction="row"
          sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', mt: SPACING.XXL }}
        >
          <Button variant="contained" onClick={handleConfirmNavigate}>{`${translate(
            'header.modalOk'
          )}`}</Button>
          <Button variant="contained" onClick={handleCloseModal}>{`${translate(
            'header.modalCancel'
          )}`}</Button>
        </Stack>
      </Box>
    );

    const logo = (
      <Box component="img" src={ASSETS.logo.logoSingle} sx={{ width: size, height: size, ...sx }} />
    );

    const logoWithTitle = (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {logo}
        <Box component="img" src={ASSETS.logo.logoText} height={25} marginLeft={0.25} />
      </Box>
    );

    if (disabledLink) {
      return withTitle ? logoWithTitle : logo;
    }

    return (
      <>
        <Button
          type="button"
          variant="text"
          onClick={handleLogoCLick}
          sx={{ display: 'contents', cursor: 'pointer' }}
        >
          {withTitle ? logoWithTitle : logo}
        </Button>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {modalBody}
        </Modal>
      </>
    );
  }
);

export default Logo;
