export enum API {
  TICKET = '/api/ticket',
  STRIPE_CHECKOUT_SESSION = '/payment/stripe/checkout-session',
  STRIPE_SESSION_STATUS = '/payment/stripe/session-status',
  PAYPAL_CREATE_ORDER = '/payment/paypal/create-order',
  PAYPAL_CAPTURE_ORDER = '/payment/paypal/capture-order',
  ORDER = '/order',
  INVOICE = '/invoice',
  ADDRESS_AUTOCOMPLETE = '/google-maps/autocomplete',
  ADDRESS_DISTANCE = '/google-maps/distance'
}
