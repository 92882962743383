import { Navigate, useRoutes } from 'react-router-dom';

import GuestGuard from 'src/auth/GuestGuard';
import CompactLayout from 'src/layouts/compact';
import PageLayout from 'src/layouts/page/PageLayout';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import PaymentSuccess from 'src/sections/payment-result/PaymentSuccess';
import PaymentUnsuccessful from 'src/sections/payment-result/PaymentUnsuccessful';
import StripeCheckStatus from 'src/sections/payment-result/StripeCheckStatus';
import PageWelcome from 'src/pages/page-welcome/PageWelcome';

import { Page404, LoginPage, PageBooking } from './elements';
import { PATH_BOOKING, PATH_NOT_FOUND, PATH_WELCOME, SUBPATH_BOOKING } from './paths';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: PATH_WELCOME.root,
      element: <PageWelcome />,
    },
    {
      path: PATH_BOOKING.root,
      element: <PageLayout />,
      children: [
        { path: PATH_BOOKING.root, element: <PageBooking /> },
        { path: SUBPATH_BOOKING.success, element: <PaymentSuccess /> },
        { path: SUBPATH_BOOKING.error, element: <PaymentUnsuccessful /> },
        { path: SUBPATH_BOOKING.stripe, element: <StripeCheckStatus /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: PATH_NOT_FOUND, element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to={`/${PATH_NOT_FOUND}`} replace /> },
  ]);
}
