import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import SPACING from 'src/theme/spacing';
import { PATH_BOOKING } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import { pageWidth } from './constants';

const PaymentUnsuccessful: React.FC = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const orderId = params.get('order_id');

  const onReturnToBooking = () => {
    navigate(`${PATH_BOOKING.root}?order_id=${orderId}`);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Paper
        sx={{
          padding: SPACING.XL,
          width: pageWidth,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          {`${translate('payment_unsuccessful.title')}`}
        </Typography>
        <Typography variant="body1" sx={{ marginY: SPACING.XXL, textAlign: 'center' }}>
          {`${translate('payment_unsuccessful.body')}`}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="iconoir:warning-octagon" />}
          onClick={onReturnToBooking}
        >
          {`${translate('payment_unsuccessful.return')}`}
        </Button>
      </Paper>
    </Box>
  );
};

export default PaymentUnsuccessful;
