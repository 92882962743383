import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

import { useLocales } from 'src/locales';
import { useAuthContext } from 'src/auth/useAuthContext';
import { PATH_AUTH } from 'src/routes/paths';
import Logo from 'src/components/logo/Logo';
import Iconify from 'src/components/iconify/Iconify';
import AccountPopover from 'src/layouts/dashboard/header/AccountPopover';
import useResponsive from 'src/hooks/useResponsive';

import { buttonIconMargin, logoSize, mobileLogoSize } from './constants';
import { StyledContainer } from './styles';

export interface HeaderProps {
  step?: number;
}

export default function Header({ step }: HeaderProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const isMobile = useResponsive('down', 'sm');

  return (
    <StyledContainer>
      <Logo
        disabledLink={false}
        size={isMobile ? mobileLogoSize : logoSize}
        withTitle={!isMobile}
        step={step}
      />

      {isInitialized && isAuthenticated && <AccountPopover />}

      {isInitialized && !isAuthenticated && (
        <Button variant="contained" onClick={() => navigate(PATH_AUTH.login)}>
          <Iconify sx={{ mr: buttonIconMargin }} icon="material-symbols:lock-outline" />
          <span>{`${translate('header.logIn')}`}</span>
        </Button>
      )}
    </StyledContainer>
  );
}
