const logoPath = '/logo';
const iconsPath = '/assets/icons';
const imagesPath = '/assets/images';

const logo = {
  logoSingle: `${logoPath}/logo_single.svg`,
  logoText: `${logoPath}/logo_text.png`,
};

const icons = {
  common: {
    chevronRight: `${iconsPath}/common/chevron-right.svg`,
  },
};

const images = {
  bags: {
    small: `${imagesPath}/bags/small-bag.png`,
    medium: `${imagesPath}/bags/medium-bag.png`,
    large: `${imagesPath}/bags/large-bag.png`,
  },
};

const ASSETS = {
  logo,
  icons,
  images,
};

export default ASSETS;
