import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Paper, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import SPACING from 'src/theme/spacing';
import { PATH_BOOKING, PATH_NOT_FOUND } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import axiosInstance from 'src/utils/axios';
import { API } from 'src/utils/api.enum';
import { OrderResponse } from 'src/api/types/order';
import { PaymentStatus } from 'src/types/payment';
import { invoiceFilename, pageWidth } from './constants';

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const [params] = useSearchParams();

  const orderId = params.get('order_id');

  const checkOrderId = useCallback(async () => {
    try {
      const response = await axiosInstance.get<OrderResponse>(`${API.ORDER}/${orderId}`);
      if (response.data.payment.status !== PaymentStatus.PAID) {
        navigate(PATH_NOT_FOUND);
      }
    } catch (error) {
      navigate(PATH_NOT_FOUND);
    }
  }, [orderId, navigate]);

  useEffect(() => {
    checkOrderId();
  }, [orderId, checkOrderId]);

  const onDownload = async () => {
    const response = await axiosInstance.get(`${API.INVOICE}/${orderId}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', invoiceFilename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onMakeNewBooking = () => {
    navigate(PATH_BOOKING.root);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Paper
        sx={{
          padding: SPACING.XL,
          width: pageWidth,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" gutterBottom>
          {`${translate('payment_success.title')}`}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: SPACING.LG, textAlign: 'center' }}>
          {`${translate('payment_success.body1')}`}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Iconify icon="iconoir:download" />}
          onClick={onDownload}
          sx={{ marginBottom: SPACING.XXL }}
        >
          {`${translate('payment_success.download')}`}
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" sx={{ marginBottom: SPACING.LG, textAlign: 'center' }}>
            {`${translate('payment_success.body2')}`}
          </Typography>
          <Button variant="contained" color="primary" onClick={onMakeNewBooking}>
            {`${translate('payment_success.new')}`}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default PaymentSuccess;
