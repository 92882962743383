import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ORDER_PARAM, SESSION_PARAM } from 'src/constants/api';

import { PATH_BOOKING } from 'src/routes/paths';
import { PaymentStatus, PaymentStatusResponse } from 'src/types/payment';
import { API } from 'src/utils/api.enum';
import axiosInstance from 'src/utils/axios';

const StripeCheckStatus: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const orderId = params.get(ORDER_PARAM);
  const sessionId = params.get(SESSION_PARAM);

  const getOrderStatus = useCallback(async () => {
    const response = await axiosInstance.get<PaymentStatusResponse>(API.STRIPE_SESSION_STATUS, {
      params: {
        session_id: sessionId,
      }
    });
    if (response.data.status === PaymentStatus.PAID) {
      navigate(`${PATH_BOOKING.success}?${ORDER_PARAM}=${response.data.order_id}`);
    } else {
      navigate(`${PATH_BOOKING.error}?${ORDER_PARAM}=${response.data.order_id}`);
    }
  }, [navigate, sessionId]);

  useEffect(() => {
    if (orderId && sessionId) {
      getOrderStatus();
    }
  }, [orderId, sessionId, getOrderStatus]);

  return (
    <div />
  );
};

export default StripeCheckStatus;
