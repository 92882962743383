import { Outlet } from 'react-router-dom';
import { Stack, Container, useTheme } from '@mui/material';
import { headerHeight } from 'src/components/header/constants';
import { pagePaddingBottom } from './constants';

export default function PageLayout() {
  const theme = useTheme();

  return (
    <Container component="main">
      <Stack
        sx={{
          paddingTop: theme.typography.pxToRem(headerHeight),
          paddingBottom: theme.typography.pxToRem(pagePaddingBottom),
        }}
      >
        <Outlet />
      </Stack>
    </Container>
  );
}
