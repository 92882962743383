import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  Typography,
  Zoom,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { headerHeight } from 'src/components/header/constants';
import Iconify from 'src/components/iconify/Iconify';
import { pagePaddingBottom } from 'src/layouts/page/constants';
import { useLocales } from 'src/locales';
import { PATH_BOOKING } from 'src/routes/paths';
import SPACING from 'src/theme/spacing';

import { StyledTooltip } from './styles';

const WelcomeComponent: React.FC = () => {
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleNavBooking = () => {
    navigate(PATH_BOOKING.root);
  };

  return (
    <Box
      maxWidth="md"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        paddingTop: theme.typography.pxToRem(headerHeight),
        paddingBottom: theme.typography.pxToRem(pagePaddingBottom),
        minHeight: `calc(100vh - ${headerHeight}px)`,
      }}
    >
      <Box sx={{ marginY: SPACING.XL }}>
        <Typography variant="h4" component="h1" textAlign="center" lineHeight={2}>
          {`${translate('welcome_page.title')}`}
        </Typography>
        <Typography variant="body1" textAlign="center" lineHeight={2}>
          {`${translate('welcome_page.subtitlerow1')}`}
        </Typography>
        <Typography variant="body1" textAlign="center" lineHeight={2}>
          {`${translate('welcome_page.subtitlerow2')}`}
        </Typography>
        <Typography variant="h4" component="h1" textAlign="center" lineHeight={2}>
          {`${translate('welcome_page.subtitle2')}`}
        </Typography>
      </Box>
      <Box
        mb={SPACING.SM}
        p={SPACING.XL}
        border={1}
        borderRadius={2}
        borderColor="grey.300"
        width="100%"
        maxWidth="sm"
      >
        <Stack
          spacing={SPACING.LG}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Iconify icon="ph:rocket-duotone" width={58} />
          <Typography variant="h6" gutterBottom marginBottom={SPACING.MD}>
            {`${translate('welcome_page.subtitle3')}`}
          </Typography>
          <Typography variant="body1" gutterBottom textAlign="center" position="relative">
            {`${translate('welcome_page.message1')}`}
            <StyledTooltip
              title={
                <Stack spacing={SPACING.XS} padding={SPACING.XS}>
                  <Typography variant="body2">{`${translate('welcome_page.tooltip1')}`}</Typography>
                  <Box display="flex" alignItems="flex-start">
                    <Iconify icon="tabler:point-filled" minWidth={14} mt={0.3} mr={0.5} />
                    <Typography variant="body2">
                      {`${translate('welcome_page.tooltip2')}`}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="flex-start">
                    <Iconify icon="tabler:point-filled" minWidth={14} mt={0.3} mr={0.5} />
                    <Typography variant="body2">
                      {`${translate('welcome_page.tooltip3')}`}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="flex-start">
                    <Iconify icon="tabler:point-filled" minWidth={14} mt={0.3} mr={0.5} />
                    <Typography variant="body2">
                      {`${translate('welcome_page.tooltip4')}`}
                    </Typography>
                  </Box>
                </Stack>
              }
              placement="top-start"
              TransitionComponent={Zoom}
            >
              <Box
                component="span"
                data-testid="info-icon"
                sx={{
                  lineHeight: 0,
                  width: 'fit-content',
                  display: 'inline-block',
                  position: 'absolute',
                  top: '26px',
                  ml: '3px',
                  cursor: 'pointer',
                }}
              >
                <Iconify icon="eva:info-outline" width={18} sx={{ display: 'inline-block' }} />
              </Box>
            </StyledTooltip>
          </Typography>
        </Stack>
      </Box>

      <FormControlLabel
        sx={{ margin: 0 }}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            name="checkedInConfirmation"
            color="primary"
          />
        }
        label={
          <Typography variant="body1" display="block" sx={{ mx: 1 }}>
            {`${translate('welcome_page.checkbox')}`}
          </Typography>
        }
      />

      <Button
        variant="contained"
        color="primary"
        size="large"
        disabled={!checked}
        onClick={handleNavBooking}
        sx={{ mt: SPACING.SM }}
      >
        {`${translate('welcome_page.book_now')}`}
      </Button>
      <Box
        mt={SPACING.XXL}
        maxWidth="sm"
        width="100%"
        display="flex"
        justifyContent="space-between"
      >
        <Link
          href="#"
          underline="none"
          sx={{
            ':hover': {
              color: theme.palette.primary.main,
              filter: 'brightness(1.2)',
            },
          }}
        >
          {`${translate('welcome_page.terms')}`}
        </Link>
        <Link
          href="#"
          underline="none"
          sx={{
            ':hover': {
              color: theme.palette.primary.main,
              filter: 'brightness(1.2)',
            },
          }}
        >
          {`${translate('welcome_page.policy')}`}
        </Link>
      </Box>
    </Box>
  );
};

export default WelcomeComponent;
