import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';

import { useLocales } from 'src/locales';

import Header from 'src/components/header/Header';

import WelcomeComponent from 'src/sections/welcome/WelcomeComponent';

export default function PageWelcome() {
  const { translate } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${translate('welcome_page_title')}`}</title>
      </Helmet>
      <Header />
      <Container
        component="main"
        sx={{
          maxWidth: '100% !important',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <WelcomeComponent />
      </Container>
    </>
  );
}
